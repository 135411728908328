<template>
  <v-tabs align-with-title>
    <template v-for="item in parsedMenu">
      <v-divider v-if="item.type === 'divider'" :key="item.title" vertical />

      <v-tab
        v-else
        :key="item.title"
        :to="firstItemOfGroup(item)"
        :disabled="item.disabled"
      >
        <v-btn v-if="!item.items" :ripple="false" text class="min-button">
          <v-icon color="primary" left>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>

        <v-menu
          v-else
          :key="item.title"
          transition="scroll-y-transition"
          offset-y
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <v-btn :ripple="false" text class="min-button" v-on="on">
              <v-icon color="primary" left>{{ item.icon }}</v-icon>
              {{ item.title }}

              <v-icon right dense>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-card color="primary">
            <v-list width="300">
              <v-list-item
                v-for="subItem in activeSectionSubitems(item)"
                :key="subItem.title"
                :to="{ name: subItem.to }"
                color="primary"
                exact
              >
                <v-list-item-content>
                  {{ subItem.title }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-tab>
    </template>
  </v-tabs>
</template>

<script>
import { deepFilter, flattenTree } from '@utils/helper-functions.js'
import routes from '@router/routes.js'
import { authComputed } from '@state/helpers.js'
import { mapMutations } from 'vuex'
import menu from '@router/menu.js'

const mappedRoutes = flattenTree(routes).reduce(
  (acc, route) => ({ ...acc, [route.name]: route }),
  {}
)

export default {
  name: 'NavHorizontal',
  data: () => ({
    parsedMenu: [],
  }),
  computed: {
    ...authComputed,
    dark: {
      get() {
        return this.$store.state.ui.dark_mode
      },
      set(value) {
        return this.setDarkMode(value)
      },
    },
  },
  mounted() {
    const parsedMenu = deepFilter(menu, (item) => {
      const targetRoute = mappedRoutes[item.to]

      // WIP routes are hidden with a warning
      if (!targetRoute) {
        if (this.isDev) {
          console.warn(item, `Is not linked to any route so it's been hidden.`)
        }
        return false
      } else {
        const { section, action, does } = this.routeDoesRequirePermission(
          targetRoute
        )

        if (does) {
          // If the route requires permissions and is correctly configured check permission
          const can =
            this.canUser(section, action) && this.canUser(section, 'menu')
          return can
        } else {
          return true
        }
      }
    })

    this.parsedMenu = parsedMenu
      .map((section) => [...section.items, { type: 'divider' }])
      .flat(1)
      .slice(0, -1)
  },
  methods: {
    ...mapMutations('ui', {
      setSection: 'SET_SECTION',
      setDarkMode: 'SET_DARK_MODE',
    }),
    activeSectionItems(items) {
      return items.filter(
        (item) =>
          !item.section || (item.section && this.canUserSee(item.section))
      )
    },
    activeSectionSubitems(item) {
      if (!item.items) return []

      return item.items.filter(
        (subitem) =>
          !item.section ||
          !subitem.action ||
          (this.canUser(subitem.section || item.section, subitem.action) &&
            !this.denyUser(subitem.section || item.section, subitem.denyAction))
      )
    },
    routeDoesRequirePermission(route) {
      const { section, action } = route.meta || {}
      if (section || action) return { does: true, section, action }
      else return { does: false }
    },
    firstItemOfGroup(item) {
      if (item.to) return item.to

      const items = this.activeSectionSubitems(item)
      if (!items || !items.length) return { to: '/' }

      return { name: items[0].to }
    },
  },
}
</script>

<style scoped>
.min-button::before {
  display: none;
}
</style>
