<template>
  <div>
    <span v-if="$vuetify.breakpoint.mdAndUp" class="subtitle-2">
      {{ first_name }} {{ last_name }}
    </span>

    <v-avatar color="indigo" size="36" class="ml-3">
      <img v-if="profile_picture" :src="profile_picture" />
      <span v-else class="white--text">
        {{ avatarPlaceHolder }}
      </span>
    </v-avatar>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UserToolbarInfo',
  computed: {
    ...mapState('ui', ['dark_mode']),
    ...mapState('auth', {
      first_name: (state) => state.current.user.first_name,
      last_name: (state) => state.current.user.last_name,
      profile_picture: (state) => state.current.user.profile_picture,
    }),
    avatarPlaceHolder() {
      if (!this.first_name && this.last_name) return 'TS'
      return `${this.first_name?.[0]}${this.last_name?.[0]}`.toUpperCase()
    },
  },
}
</script>

<style scoped></style>
